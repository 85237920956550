import React, { forwardRef, ButtonHTMLAttributes } from "react"
import { cn } from "@/utils/cn"
import { Loader } from "./loader"

export type ButtonKind =
  | "primary"
  | "secondary"
  | "tertiarty"
  | "ghost"
  | "invert"

const buttonKindColors = {
  primary: `bg-sr-text-primary text-sr-bg-primary hover:text-sr-text-tertiary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:text-sr-text-secondary disabled:bg-sr-500 disabled:dark:bg-sr-700 disabled:dim:bg-sr-700`,
  secondary: `bg-sr-bg-primary border border-sr-border-primary hover:border-sr-border-secondary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:bg-sr-bg-tertiary`,
  tertiarty: `bg-sr-bg-primary border border-sr-border-secondary hover:border-sr-border-primary active:text-sr-text-secondary disabled:border-sr-border-tertiary disabled:bg-sr-bg-tertiary`,
  ghost: `bg-transparent text-sr-text-primary`,
  invert: `bg-white dark:bg-black dim:bg-white dim:text-black disabled:border-sr-border-tertiary disabled:border-sr-border-tertiary disabled:dim:text-sr-text-secondary disabled:text-sr-text-secondary disabled:bg-sr-500 disabled:dark:bg-sr-700 disabled:dim:bg-sr-700`,
}

const buttonSizeSpec = {
  sm: `text-sm h-[28px] px-5`,
  md: `text-base h-[32px] px-6`,
  lg: `text-base h-[40px] px-6`,
  full: `text-base h-[32px] w-full`,
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  kind?: ButtonKind
  size?: "sm" | "md" | "lg" | "full"
  isIcon?: boolean
  className?: string
  loading?: boolean | string
  uppercase?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      kind = "primary",
      size = "md",
      className,
      isIcon,
      loading,
      uppercase,
      ...props
    },
    ref
  ): JSX.Element => {
    const buttonStyle = buttonKindColors[kind]
    const buttonSize = buttonSizeSpec[size]
    return (
      <button
        ref={ref}
        className={cn(
          `flex items-center justify-center gap-x-3 rounded-md border-0 duration-300`,
          loading ? "cursor-not-allowed text-opacity-50" : "",
          buttonStyle,
          buttonSize,
          isIcon ? "size-8 p-0" : "",
          className,
          uppercase && "uppercase"
        )}
        {...props}
      >
        {loading && (
          <Loader
            className={cn(
              kind === "primary"
                ? `
                  invert
                  dark:invert-0
                `
                : "",
              "h-3 w-3"
            )}
            kind="simple"
          />
        )}
        {typeof loading === "string" ? loading : children}
      </button>
    )
  }
)
